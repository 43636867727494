<template>
  <div>
    <vue-element-loading
    :active="appLoading"
    :is-full-screen="true"
    background-color="#FFFFFF"
    color="#005f32"
    spinner="bar-fade-scale"
  />
  <div class="genealogy-body genealogy-scroll">
    <div class="genealogy-tree">
      <div>
        <span class="itemHeading">TEAM HIERARCHY</span>
      </div>
      <ul>
        <li>
          <a href="javascript:void(0);">
            <v-card color="#b8f5bf"  width="180px" flat>
            <div class="member-view-box">
              <div class="member-details">
                <h3  style="font-family:kumbhMedium;font-size:17px;">{{ headEmp.name }}</h3>
                <p style="font-family:kumbhMedium;font-size:14px;">  {{ headEmp.position }}  <br> <span>Emp Id:</span> {{ headEmp.employeeId }}
                 </p>
                
              </div>
            </div>
          </v-card>
          </a>
          <ul>
            <employee-node v-for="(data, index) in datas" :key="index" :employee="data.CoEmployeeDetails" :sub-teams="data.subTeams" />
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios';
import EmployeeNode from './EmployeeNode.vue';

export default {
  components: {
    EmployeeNode
  },
  data() {
    return {
      headEmp: {},
      datas: [],
      appLoading: false,
    };
  },
  created() {
    this.fetchEmployeeData();
  },
  methods: {
fetchEmployeeData() {
      this.appLoading = true;
      axios({
        url: "/coEmployee/subTeamHierarchy/v2",
        method: "GET",
        params: {
          employeeId: this.$route.query.id
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.headEmp = response.data.HeadEmp;
          this.datas = response.data.datas;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

  }
};
</script>

<style>
.genealogy-scroll::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
.genealogy-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #e4e4e4;
}
.genealogy-scroll::-webkit-scrollbar-thumb {
  background: #212121;
  border-radius: 10px;
  transition: 0.5s;
}
.genealogy-scroll::-webkit-scrollbar-thumb:hover {
  background: #d5b14c;
  transition: 0.5s;
}



.genealogy-body{
  white-space: nowrap;
  overflow-y: hidden;
  padding: 50px;
  min-height: 500px;
  padding-top: 10px;
}
.genealogy-tree ul {
  padding-top: 20px; 
  position: relative;
  padding-left: 0px;
  display: flex;
}
.genealogy-tree li {
  float: left; text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 5px;
}
.genealogy-tree li::before, .genealogy-tree li::after{
  content: '';
  position: absolute; 
top: 0; 
right: 50%;
  border-top: 2px solid #ccc;
  width: 50%; 
height: 18px;
}
.genealogy-tree li::after{
  right: auto; left: 50%;
  border-left: 2px solid #ccc;
}
.genealogy-tree li:only-child::after, .genealogy-tree li:only-child::before {
  display: none;
}
.genealogy-tree li:only-child{ 
  padding-top: 0;
}
.genealogy-tree li:first-child::before, .genealogy-tree li:last-child::after{
  border: 0 none;
}
.genealogy-tree li:last-child::before{
  border-right: 2px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}
.genealogy-tree li:first-child::after{
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}
.genealogy-tree ul ul::before{
  content: '';
  position: absolute; top: 0; left: 50%;
  border-left: 2px solid #ccc;
  width: 0; height: 20px;
}
.genealogy-tree li a{
  text-decoration: none;
  color: #666;
  font-family: arial, verdana, tahoma;
  font-size: 11px;
  display: inline-block;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

.genealogy-tree li a:hover+ul li::after, 
.genealogy-tree li a:hover+ul li::before, 
.genealogy-tree li a:hover+ul::before, 
.genealogy-tree li a:hover+ul ul::before{
  border-color:  #fbba00;
}


.member-view-box{
  padding:10px 15px;
  text-align: center;
  border-radius: 4px;
  position: relative;
}
.member-image{
  width: 60px;
  position: relative;
}
.member-image img{
  width: 60px;
  height: 60px;
  border-radius: 6px;
background-color :#000;
z-index: 1;
}

</style>